.playerLayout {
    position: absolute;
    display: flex;
    bottom: 0;
    width: 100%;
    z-index: 2;
    box-sizing: border-box;
    padding: 20px 0px 20px 0px;
    min-width: var(--root-min-width);
    height: var(--main-player-size);
    align-items: center;
    justify-content: center;
}

.playerDiv {
    width: 90%;
    height: 100%;
    border-radius: 15px;
    background-color: #f3f3f3;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid rgba(156, 109, 255, 0.7);
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
    display: flex;
}

.musicInfoDiv,
.musicControlDiv,
.volumeControlDiv {
    height: 100%;
    width: 100%;
}

.musicInfoDiv {
    display: flex;
    overflow: hidden;
    align-items: center;
    gap: 10px;
}

.infoText {
    font-weight: 700;
}

.songName {
    font-size: 1.1rem;
}

.songArtist {
    font-size: 0.9rem;
    color: var(--hint-color);
}

.albumArt {
    border-radius: 10px;
}

.musicControl {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}

.musicControl,
.progressBar {
    width: 100%;
    height: 50%;
    user-select: none;
}

.controlIcon {
    cursor: pointer;
    width: initial;
    height: 100%;
    box-sizing: border-box;
    padding: 3px;
}

.controlIcon:hover {
    padding: 1px;
}

.progressBar {
    display: flex;
    align-items: center;
}

.progressTime {
    width: 45px;
    color: var(--hint-color);
    font-size: 0.9rem;
    flex-shrink: 0;
}

.volumeControl {
    width: 32%;
    height: 100%;
    min-width: 140px;
    float: right;
    gap: 10px;
    margin-right: 50px;
    display: flex;
    align-items: center;
}

.volumePer {
    width: 55px;
    flex-shrink: 0;
}