@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
    --root-min-width: 850px;
    --main-font-size: 1rem;
    --elment-margin: 5px;
    --border-radius: 15px;
    --main-player-size: 135px;
    --side-bar-size: 18.8%;
    --side-min-size: 280px;
}

:root {
    --primary-color: #9c6dff;
    --secondary-color: #f3f3f3;
    --hint-color: #888888;
    --light-hint-color: #525252
}

p {
    margin: 0;
    padding: 0;
}

html {
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 0;
}

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    overflow: hidden;
    font-size: var(--main-font-size);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}

#root {
    width: 100%;
    height: 100%;
    min-width: var(--root-min-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

table,
thead,
tbody {
    display: block !important;
    width: 100% !important;
}

table {
    height: 100% !important;
}

tr {
    width: 100% !important;
    display: flex !important;
}
