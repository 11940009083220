.SearchSideBarDiv {
    padding: 15px 15px 0px 15px;
    height: 100%;
    display: flex;
    width: var(--side-bar-size);
    flex-shrink: 0;
    gap: 15px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
}

.logoDiv {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    border-radius: var(--border-radius);
    height: 15%;
    border: 1px solid black;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 1.4rem;
    color: #515151;
    letter-spacing: -0.05em;
    font-weight: 700;
    width: 100%;
}

.menu {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.navigateHome {
    width: 100%;
    height: 55px;
    gap: 5px;
    font-size: 1.2rem;
}

.menuIcons,
.menuIcons {
    height: 100%;
}

.menuIcons {
    width: initial;
}

.menuTitle {
    display: inline-flex;
    align-items: center;
}

.categoryBar {
    border: 1px solid black;
}

.categoryText {
    font-size: 1.3rem;
    font-weight: 600;
}

.searchLayout {
    width: 100%;
    user-select: none;
}

.searchBarDiv {
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    padding: 5px 10px 5px 5px;
    box-sizing: border-box;
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
}

.searchBar {
    height: 100%;
    width: 100%;
    font-weight: 600;
    font-size: 0.95rem;
    border: 0;
}

.searchBar:focus {
    outline: none;
    border-bottom: 1px solid;
}

.searchBar::placeholder {
    color: var(--hint-color);
}

.artistList {
    width: 100%;
    display: flex;
    overflow: overlay;
    max-height: 100%;
    box-sizing: border-box;
    padding-bottom: var(--main-player-size);
    gap: 10px;
    flex-direction: column;
}

.navigateArtistLayout {
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondary-color);
}

.navigateArtist {
    width: 100%;
    font-size: 1rem;
    z-index: 1;
    justify-content: space-between;
}

.icons {
    width: 1.8rem;
    height: 1.8rem;
}

.artist {
    display: flex;
    overflow: hidden;
    gap: 10px;
    align-items: center;
    flex-direction: row;
}

.artistImg {
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 25px;
}

.nestedListDiv {
    box-sizing: border-box;
    padding: 5px;
}

.nestedListDiv:not(:first-of-type) {
    border-top: 1px solid #626262;
}

.nestedListItem {
    padding: 5px 10px;
}

.albumInfo {
    overflow: hidden;
    width: 80%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.year {
    color: var(--light-hint-color);
    font-size: 0.9rem;
}

.albumInfo > .albumArt {
    border-radius: 5px;
}
