.trackView {
    width: 100%;
}

.trackView:not(:first-of-type) {
    margin-top: 50px;
}

.albuminfoDiv {
    display: flex;
    width: 100%;
    gap: var(--elment-margin);
    align-items: center;
}

.artistImage {
    object-fit: cover;
    border-radius: 25px;
}

.albumArt {
    border-radius: 10px;
}

.albumTempDiv {
    width: 180px;
    height: 180px;
    flex-shrink: 0;
}

.albumName {
    font-size: 2.8em;
    font-weight: 600;
}

.albumData,
.artistDiv {
    display: flex;
    align-items: center;
    gap: var(--elment-margin);
}

.tableDiv {
    margin-top: 20px;
    padding-bottom: 10px;
    white-space: nowrap;
    background-color: #fff;
    overflow: hidden;
    border-radius: var(--border-radius);
    border: 1px solid #919191;
    user-select: none;
    box-shadow: inherit;
}

.tableBodyRow > td,
.tableHeadRow > th {
    padding: 0;
}

.thead {
    box-sizing: border-box;
}

.tbody {
    overflow-y: overlay;
    overflow-x: hidden;
}

.tbody::-webkit-scrollbar {
    width: 6px;
}

.tbody::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
}

.tbody::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 20px;
}

.tableBodyRow {
    height: 50px;
}

.tableBodyRow > td,
.tableHeadRow > th {
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
    overflow: hidden;
}

.tableBodyRow > td {
    height: 100%;
    display: flex;
    flex-shrink: 0;
    font-size: 0.95rem;
    align-items: center;
}

.tableHeadRow > th {
    height: 100%;
    display: flex;
    font-weight: 600;
    font-size: 0.9rem;
    border: 0;
}

.songCell,
.timeCell {
    color: var(--hint-color);
}

.timeCell,
.timeCellHead {
    justify-content: flex-end;
    text-align: right;
}
