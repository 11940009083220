.aritstLayout {
    padding: 0px 30px 0px 30px;
    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    height: 280px;
    background: linear-gradient(180deg, #000000 0%, rgba(81, 81, 81, 0.93) 0.01%, rgba(255, 255, 255, 0) 100%);
    justify-content: center;
}

.aritstFirst {
    width: 1010px;
    height: 100%;
    display: flex;
    align-items: center;
}

.aritstInfo {
    height: 100%;
    display: flex;
    overflow: overlay;
    z-index: 0;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
}

.aritstPlay {
    width: 72%;
    height: 100%;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
}

.albumLayout {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0px 30px 0px 30px;
    margin-bottom: var(--main-player-size);
    justify-content: center;
}

.albumDiv {
    width: 940px;
}

.infoArtistImg {
    filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.25));
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 100px;
}

.artistName {
    font-weight: 600;
    font-size: 4rem;
}

.artistAlbumlist {
    box-sizing: border-box;
    width: 28%;
    max-height: 75%;
    overflow: overlay;
}

.artistAlbumlist::-webkit-scrollbar {
    display: none;
}

.artistAlbumItem {
    display: flex;
    user-select: none;
    gap: var(--elment-margin);
    align-items: center;
    margin-top: 5px;
}

.artistAlbumImg {
    border-radius: 5px;
}

.playDiv {
    display: flex;
    gap: 5px;
    align-items: center;
}

.playIcon {
    cursor: pointer;
    padding: 2px;
}

.playIcon:hover {
    padding: 0;
}

.playIcon:active {
    padding: 5px;
}

.playText {
    user-select: none;
    font-weight: 600;
    font-size: 1.1rem;
}